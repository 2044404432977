<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Nuevo Libro</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Nombre del Libro"
                required
                outlined
                hide-details
                v-model="book.book"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Precio del Libro"
                required
                outlined
                hide-details
                v-model="book.price"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-file-input
                accept="image/png, image/jpeg"
                label="Portada de Imagen del Libro"
                placeholder="Seleccione su imagen"
                prepend-icon=""
                prepend-inner-icon="mdi-camera"
                outlined
                :show-size="1000"
                v-model="book.image"
                @change="preview"
              />
            </v-col>
          </v-row>
          <v-row v-if="book.src">
            <v-col cols="12" sm="12">
              <v-img class="border" :src="book.src" height="250" contain />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add()" :loading="isLoading">
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'AddBook',
  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },
  props: { book: Object, clean: { type: Function } },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    add: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + 'book', this.toFormData(this.book))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    preview(event) {
      if (event) {
        this.book.src = URL.createObjectURL(event);
      }
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
  },
};
</script>

<style></style>
